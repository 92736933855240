.btn,
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  // background: url(/images/btn-red.png) no-repeat !important;
  // background-size: 100% 100% !important;
  background-color: #07f468 !important;
  font-size: tovw(18);
  text-transform: uppercase;
  color: #000000 !important;
  height: tovw(54);
  line-height: tovw(54);
  padding: 0 tovw(15);
  outline: none !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  min-width: tovw(220);
  position: relative;
  font-weight: normal;
  transition: transform 0.25s ease;
  font-family: $font-heading;
  border-radius: tovw(27);
  &--blue {
    background-color: #5849fa !important;
  }
  &--yellow {
    background-color: #f2be42 !important;
  }
  &--blue-light {
    background-color: #07abda !important;
  }
  &--green {
    background-color: #22bf86 !important;
  }
  &--pink {
    background-color: #ff85cd !important;
  }
  &--purple {
    background-color: #07f468 !important;
    font-weight: bold;
  }
  &--gradient {
    background-color: #07f468;
    height: tovw(60);
    line-height: tovw(60);
    color: #000000;
    font-size: tovw(18);
    font-weight: bold;
    min-width: tovw(190);
    width: 100%;
    border-radius: tovw(10);
    transition: background 0.3s ease-out, color 0.3s ease-out;
  }
  &--gradient2 {
    background-image: -moz-linear-gradient(
      0deg,
      rgb(198, 0, 172) 9%,
      rgb(113, 0, 205) 100%
    ) !important;
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(198, 0, 172) 9%,
      rgb(113, 0, 205) 100%
    ) !important;
    background-image: -ms-linear-gradient(
      0deg,
      rgb(198, 0, 172) 9%,
      rgb(113, 0, 205) 100%
    ) !important;
    height: tovw(40) !important;
    line-height: tovw(40) !important;
    border-radius: tovw(10);
    color: #fff !important;
    font-weight: bold;
    &:hover {
      background-image: -moz-linear-gradient(
        0deg,
        rgb(198, 0, 172) 0%,
        rgb(254, 187, 11) 99%
      ) !important;
      background-image: -webkit-linear-gradient(
        0deg,
        rgb(198, 0, 172) 0%,
        rgb(254, 187, 11) 99%
      ) !important;
      background-image: -ms-linear-gradient(
        0deg,
        rgb(198, 0, 172) 0%,
        rgb(254, 187, 11) 99%
      ) !important;
    }
  }

  @include min-screen(1200px) {
    // &:hover {
    //   transform: translateY(-0.5rem);
    // }
  }
  &--normal {
    background: #102d7f !important;
    border: 1px solid #102d7f !important;
    border-radius: tovw(32);
    height: tovw(32);
    line-height: tovw(32);
    text-transform: none;
    font-weight: normal;
    color: #fff !important;
    min-width: initial !important;
    font-size: tovw(18) !important;
    padding: 0 !important;
    font-style: normal !important;
    &--active {
      color: #fff !important;
      border-color: transparent !important;
      background: transparent !important;
      background-image: -moz-linear-gradient(
        180deg,
        rgb(39, 31, 149) 0%,
        rgb(4, 185, 242) 20%,
        rgb(212, 28, 148) 54%,
        rgb(241, 195, 14) 84%,
        rgb(240, 50, 3) 100%
      ) !important;
      background-image: -webkit-linear-gradient(
        180deg,
        rgb(39, 31, 149) 0%,
        rgb(4, 185, 242) 20%,
        rgb(212, 28, 148) 54%,
        rgb(241, 195, 14) 84%,
        rgb(240, 50, 3) 100%
      ) !important;
      background-image: -ms-linear-gradient(
        180deg,
        rgb(39, 31, 149) 0%,
        rgb(4, 185, 242) 20%,
        rgb(212, 28, 148) 54%,
        rgb(241, 195, 14) 84%,
        rgb(240, 50, 3) 100%
      ) !important;
      border-right: 0 !important;
    }
  }
}
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  @extend %grayscale;
}
