html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  font-size: tovw(18);
  color: #fff;
  font-family: $roboto;
  background-color: #000;
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    height: 100% !important;
  }
}
img {
  max-width: 100% !important;
}
#root {
  position: relative;
  min-height: 100%;
}
button {
  outline: none !important;
}
a {
  color: #fff;
  transition: color 0.25s ease;
  outline: none !important;
  &:hover {
    color: #16efb5;
    text-decoration: none;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}
.container {
  max-width: 100% !important;
  padding-left: 1rem;
  padding-right: 1rem;
  &--content {
    max-width: tovw(1400) !important;
  }
  &--content-streamers {
    max-width: tovw(1440) !important;
  }
}
.col {
  &--pr {
    padding-right: tovw(70);
  }
  &--pl {
    padding-left: tovw(70);
  }
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.swal2-shown,
.ReactModal__Body--open {
  #root {
    //filter: blur(5px);
  }
}
.swal2-container.swal2-backdrop-show {
  background-color: rgba(0, 0, 0, 0.6) !important;
  overflow-y: auto !important;
  .swal2-popup {
    display: flex;
  }
}
.swal2-container {
  .swal2-popup {
    height: auto !important;
    width: tovw(568) !important;
    min-height: tovw(300) !important;
    background: url(/images/popup-l.png) !important;
    background-size: 100% 100% !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 0 !important;
    box-shadow: none;
    justify-content: flex-start !important;
    .swal2-close,
    .close {
      position: absolute;
      width: 14px;
      height: 14px;
      background: url(#{$cdnUrl}/images/close.png) no-repeat center;
      background-size: 100%;
      top: 10px;
      right: 10px;
      transform: translateX(-50%);
      opacity: 1;
      font-size: 4rem;
      outline: none !important;
      color: #fff !important;
      font-family: serif;
      font-weight: 300;
      z-index: 9;
      text-indent: -9999px;
    }
    #swal2-content {
      font-size: tovw(20);
      color: #7100cd;
      line-height: 150%;
      text-transform: uppercase;
      p {
        font-size: tovw(20);
        color: #7100cd;
        line-height: 150%;
        text-transform: uppercase;
      }
    }
    #swal2-title,
    h2 {
      font-size: tovw(46);
      background: #c600ac;
      background: -webkit-linear-gradient(to right, #c600ac 9%, #7100cd 100%);
      background: -moz-linear-gradient(to right, #c600ac 9%, #7100cd 100%);
      background: linear-gradient(to right, #c600ac 9%, #7100cd 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      font-family: $font-heading;
      min-height: tovw(55);
      line-height: 1.5;
    }
    &.popup-image {
      background: transparent !important;
      box-shadow: none !important;
      width: auto !important;
      padding: 0 !important;
      border-radius: 0.5vw !important;
      overflow: hidden;
      img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        backface-visibility: hidden;
      }
    }
    &.popup-check-match {
      // .swal2-popup {
      background: url(/images/popup-sm.png) !important;
      background-size: 100% 100% !important;
      // }ååå
      .swal2-close,
      .close {
        width: tovw(245);
        height: tovw(115);
        background: url(#{$cdnUrl}/images/btn-close.png) no-repeat center;
        background-size: 100%;
        top: 80%;
        right: inherit;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 1060 !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0 !important;
  box-shadow: none !important;
  width: tovw(568) !important;
  min-height: tovw(300) !important;
  background: url(/images/popup-l.png);
  background-size: 100% 100%;
  border: 0 !important;
  padding: 2rem 0 !important;
  text-align: center;
  outline: none;
  .modal-description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  &.modal-history {
    width: tovw(1135) !important;
    min-height: tovw(695) !important;
    background: url(/images/popup-l.png);
    background-size: 100% 100%;
  }

  &.modal-info-streamer {
    width: tovw(1045) !important;
    min-height: tovw(580) !important;
    background: url(/images/popup-md.png);
    background-size: 100% 100%;
    padding: 0 !important;
    .modal-description {
      width: 100%;
    }
  }

  &.modal-video {
    width: tovw(1045) !important;
    min-height: tovw(675) !important;
    background: url(/images/popup-l.png);
    background-size: 100% 100%;
    .modal-description {
      width: 92%;
    }
  }

  .close {
    position: absolute;
    width: 14px;
    height: 14px;
    background: url(#{$cdnUrl}/images/close.png) no-repeat center;
    background-size: 100%;
    top: 10px;
    right: 10px;
    opacity: 1;
    font-size: 4rem;
    outline: none !important;
    color: #fff !important;
    font-family: serif;
    font-weight: 300;
    z-index: 9;
    text-indent: -9999px;
  }
  h2 {
    font-size: tovw(46);
    background: #c600ac;
    background: -webkit-linear-gradient(to right, #c600ac 9%, #7100cd 100%);
    background: -moz-linear-gradient(to right, #c600ac 9%, #7100cd 100%);
    background: linear-gradient(to right, #c600ac 9%, #7100cd 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: center;
    font-family: $font-heading;
  }
  p,
  li {
    font-size: tovw(20);
    color: #7100cd;
  }
}
#main-body {
  position: relative;
}
.main-content {
  position: relative;
  // background: url(/images/fullbg.jpg) no-repeat top center;
  // background-size: cover;
}
.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}
.ScrollbarsCustom-Track {
  width: tovw(10) !important;
  right: tovw(-35) !important;
  z-index: 1;
  background: transparent !important;
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    background: #9b4cdb;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
  .support__tab & {
    right: -70px !important;
    &:before {
      background: #febb0b;
    }
  }
}
.ScrollbarsCustom-Thumb {
  width: 100% !important;
  border-radius: 0.5vw !important;
  background: #7100cd !important;
  .support__tab & {
    background: #febb0b !important;
  }
}
#header {
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
#footer {
  position: relative;
  background: #000;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  .container {
    width: tovw(1320);
  }
  .footer-logo {
    display: inline-block;
    width: tovw(500);
    margin-bottom: tovw(18);
  }

  p {
    font-size: tovw(17);
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    margin-bottom: 0;
  }
}
.top-bar {
  position: relative;
  height: tovw(73);
  background: url(/images/bg-header.jpg) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  > .container {
    height: 100%;
    max-width: tovw(1620) !important;
    margin-left: auto;
    margin-right: auto;
    > .row {
      height: 100%;
    }
  }
  span {
    display: inline-block;
    color: #fff;
    font-size: tovw(18);
    em {
      width: 1px;
      height: 1vw;
      background: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
    }
    .text-red {
      font-size: tovw(15);
      width: tovw(26);
      height: tovw(26);
      padding-top: 1px;
      background-color: #f71f2b;
      border-radius: 50%;
      border: 1px solid #fff;
      text-align: center;
      color: #fff !important;
    }
  }
}
.nickname {
  display: inline-block;
  max-width: 8vw;
  overflow: hidden;
  vertical-align: bottom;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.logo {
  display: inline-block;
  height: tovw(44);
  margin-top: tovw(8);
  img {
    display: block;
    backface-visibility: hidden;
    width: 100%;
  }
}
.menu-link {
  cursor: pointer;
  display: inline-block;
  font-size: tovw(20);
  text-transform: uppercase;
  color: #fff;
  position: relative;
  line-height: tovw(73);
  background-color: transparent;
  font-family: $font-primary;
  font-weight: 300;
  padding: 0 tovw(18);
  z-index: 1;
  transition: all 0.3s linear;
  &.selected,
  &:hover {
    color: #fff;
    font-weight: bold;
  }
}
.btn-login {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: tovw(-180);
  transform: translateY(-50%);
  width: tovw(131);
  height: tovw(41);
  line-height: tovw(41);
  font-size: tovw(18);
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: 0.5vw;
  text-align: center;
  color: #fff;
  transition: color 0.3s ease;
  &:hover {
    color: #fccb0a;
  }
}
.btn-logout {
  position: absolute;
  top: 50%;
  right: tovw(35);
  transform: translateY(-50%);
  padding: 0.5vw;
  font-size: tovw(18);
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  transition: color 0.3s ease;
  &:hover {
    color: #fccb0a;
  }
}
.section {
  position: relative;
  &--1 {
    padding-top: tovw(73);
  }
  &--2 {
    min-height: tovw(980);
    background: url("/images/bg-section2.jpg") no-repeat;
    background-size: 100% 100%;
    padding-top: tovw(80);
    padding-bottom: tovw(270);
  }
  &--3 {
    min-height: tovw(1132);
    background: url("/images/bg-section3.jpg") no-repeat;
    background-size: 100% 100%;
    padding-top: tovw(70);
    padding-bottom: tovw(115);
  }
  &--4 {
    padding-top: tovw(25);
    background: url("/images/bg-section4.jpg") no-repeat;
    background-size: 100% 100%;
    min-height: tovw(1080);
  }
  &--5 {
    padding-top: tovw(150);
    padding-bottom: tovw(100);
    background: url("/images/bg-section5.jpg") no-repeat;
    background-size: 100% 100%;
    min-height: tovw(900);
  }
  &--6 {
    padding-top: tovw(66);
    padding-bottom: tovw(60);
    background: url("/images/bg-section6.jpg") no-repeat;
    background-size: 100% 100%;
    min-height: tovw(970);
    display: none;
    &.active {
      display: block;
    }
  }
}
.title {
  text-align: center;
  &--2 {
    margin-bottom: tovw(22);
    img {
      height: tovw(124);
    }
  }
  &--3 {
    img {
      height: tovw(155);
    }
  }
  &--4 {
    img {
      height: tovw(149);
    }
  }
  &--5 {
    img {
      height: tovw(116);
    }
  }
  &--6 {
    img {
      height: tovw(127);
    }
  }
}
.text-gradient {
  font-size: tovw(44);
  font-family: $riffic;
  background: #c600ac;
  background: -webkit-linear-gradient(to right, #c600ac 9%, #7100cd 100%);
  background: -moz-linear-gradient(to right, #c600ac 9%, #7100cd 100%);
  background: linear-gradient(to right, #c600ac 9%, #7100cd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: tovw(20);
  font-weight: bold;
}
.swiper-banner {
  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-pagination-bullet {
    width: tovw(12) !important;
    height: tovw(12) !important;
    border: 2px solid #07f468;
    background-color: #fff;
    opacity: 1 !important;
    &-active {
      width: tovw(22) !important;
      height: tovw(22) !important;
    }
  }
}
.icon-play {
  height: tovw(92);
}
.img-title-star {
  font-size: tovw(45);
  text-transform: uppercase;
  color: #000;
  text-shadow: 0px tovw(2) 0px rgba(7, 244, 104, 1);
  font-family: $cruyff;
  font-weight: 900;
}
.creation {
  padding-top: tovw(30);
  padding-bottom: tovw(130);
  background: url(/images/bg-shoes.jpg) no-repeat;
  background-size: 100% 100%;
  &__video {
    position: relative;
    height: tovw(245);
    .swiper-container {
      height: 100%;
    }
    a {
      display: block;
      position: relative;
      height: 100%;
      border: tovw(2) solid #07f468;
      border-radius: tovw(20);
      overflow: hidden;
      img {
        height: 100%;
      }
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: tovw(92);
        height: tovw(92);
      }
    }
    .react-player-creation {
      border-radius: 20px;
      overflow: hidden;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 20px;
      overflow: hidden;
    }
  }
  &__title {
    // padding: 0 tovw(25);
    h3 {
      font-size: tovw(30);
      font-family: $font-heading;
      color: #000000;
      margin-bottom: tovw(5);
      text-transform: uppercase;
      font-weight: 900;
    }
    p {
      font-size: tovw(18);
      color: #000000;
    }
  }
  .img-reward {
    img {
      height: tovw(244);
    }
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-pagination-bullet {
    bottom: tovw(0) !important;
    width: tovw(12) !important;
    height: tovw(12) !important;
    background-color: #743b96;
    opacity: 1 !important;
    &-active {
      width: tovw(18) !important;
      height: tovw(18) !important;
    }
  }
}
.swiper-button-disabled {
  opacity: 0.3;
}
.swiper-next-streamer1,
.swiper-next-streamer2,
.swiper-next-qa,
.swiper-next-video-daily,
.swiper-next-video-rewards,
.swiper-next-video {
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: tovw(36) !important;
  height: tovw(81) !important;
  background: url(/images/icon-next.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  right: tovw(-40) !important;
  margin-top: tovw(-40);
  &::after {
    display: none;
  }
  &:hover {
    background: url(/images/icon-next.png) no-repeat;
    background-size: 100% 100%;
  }
}
.swiper-prev-streamer1,
.swiper-prev-streamer2,
.swiper-prev-qa,
.swiper-prev-video-daily,
.swiper-prev-video-rewards,
.swiper-prev-video {
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: tovw(36) !important;
  height: tovw(81) !important;
  background: url(/images/icon-prev.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: tovw(-40) !important;
  margin-top: tovw(-40);
  &::after {
    display: none;
  }
  &:hover {
    background: url(/images/icon-prev.png) no-repeat;
    background-size: 100% 100%;
  }
}
.list-streamers {
  display: flex;
}
.streamer {
  margin-top: tovw(20);
  padding: 0 tovw(10);
  overflow: hidden;
  // width: 33.333%;
  &__info {
    display: block;
    background-color: #07f468;
    border-radius: 10px;
    padding: tovw(2);
    overflow: hidden;
    text-align: center;
    transition: background 0.3s ease-out;
    img {
      height: tovw(280);
      object-fit: cover;
      border-top-left-radius: tovw(5);
      border-top-right-radius: tovw(5);
    }
    span {
      font-size: tovw(16);
      text-transform: uppercase;
      color: #000000;
      display: block;
      padding: tovw(5);
      background-image: none;
      transition: color 0.3 ease-out;
      border-bottom-left-radius: tovw(5);
      border-bottom-right-radius: tovw(5);
      min-height: tovw(34);
    }
  }
  .btn {
    font-size: tovw(16) !important;
    margin-top: tovw(20);
    height: tovw(60) !important;
    line-height: 1.2 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    .streamer__info {
      background-color: #000000;
      span {
        color: #07f468;
      }
    }
    .btn--gradient {
      background-color: #000 !important;
      color: #07f468 !important;
    }
  }
}
.support {
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: tovw(-23);
    margin-right: tovw(-23);
    margin-bottom: tovw(45);
  }
  &__item {
    width: 20%;
    padding: 0 tovw(23);
    a {
      opacity: 0.3;
      display: block;
      box-shadow: tovw(4) tovw(3) tovw(10) 0px rgba(0, 0, 0, 0.56);
      transition: opacity 1s ease-out;
      height: tovw(239);
      background: url(/images/bd-term.png) no-repeat;
      background-size: 100% 100%;
      text-align: center;
      border-radius: tovw(10);
      padding-top: tovw(15);
      img {
        height: tovw(170);
      }
      span {
        display: block;
        text-transform: uppercase;
        font-family: $svn;
        font-size: tovw(18);
        color: #000;
      }
    }
    &.active,
    &:hover {
      a {
        opacity: 1;
      }
    }
  }
  &__tab {
    padding: tovw(75) tovw(50) tovw(120) tovw(50);
    background: url(/images/bg-tab1.png) no-repeat;
    background-size: 100% 100%;
    margin-right: tovw(-11);
    position: relative;
    display: none;
    &--2 {
      background: url(/images/bg-tab2.png) no-repeat;
      background-size: 100% 100%;
    }
    &--3 {
      background: url(/images/bg-tab3.png) no-repeat;
      background-size: 100% 100%;
    }
    &--4 {
      background: url(/images/bg-tab4.png) no-repeat;
      background-size: 100% 100%;
    }
    &--5 {
      background: url(/images/bg-tab5.png) no-repeat;
      background-size: 100% 100%;
    }
    &.active {
      display: block;
    }
    .btn-login-form {
      position: absolute;
      bottom: tovw(-73);
      left: 50%;
      transform: translateX(-50%);
      width: tovw(311);
      z-index: 1;
    }
  }
  &__content {
    margin-bottom: tovw(45);
    &--title {
      display: inline-block;
      font-size: tovw(18);
      text-transform: uppercase;
      color: #fff;
      height: tovw(54);
      line-height: tovw(54);
      padding: 0 tovw(15);
      outline: none;
      border: 0;
      outline: none;
      box-shadow: none;
      border-radius: 0;
      min-width: tovw(185);
      position: relative;
      font-weight: bold;
      transition: transform 0.25s ease;
      font-family: $font-heading;
      border-radius: tovw(10);
      margin-bottom: tovw(20);
      text-align: center;
      font-weight: bold;
    }
    p {
      font-size: tovw(18);
      color: #000000;
      margin-bottom: 0;
    }
    a {
      color: #008000;
    }
  }
}
.video {
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: tovw(55);
    .swiper-next-video {
      right: 0 !important;
      margin-top: tovw(-80) !important;
      background: url(/images/icon-next-black.png) no-repeat;
      background-size: 100% 100%;
    }
    .swiper-prev-video {
      left: 0 !important;
      margin-top: tovw(-80) !important;
      background: url(/images/icon-prev-black.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  &__item {
    padding: 0 tovw(66);
    margin-bottom: tovw(45);
    cursor: pointer;
  }
  &__player {
    height: tovw(175);
    margin-bottom: tovw(13);
    img {
      height: tovw(175);
      width: 100%;
      object-fit: cover;
    }
  }
  &__name {
    font-size: tovw(17);
    color: #010101;
    font-family: $font-heading;
    margin-bottom: tovw(8);
    max-width: 16vw;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    font-weight: bold;
    white-space: nowrap;
  }
  &__content {
    font-size: tovw(18);
    color: #000000;
    max-width: 16vw;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.qa {
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: tovw(40);
    margin-top: tovw(55);
  }

  &__item {
    padding-left: tovw(49);
    padding-right: tovw(49);
    &:nth-of-type(2) {
      opacity: 0.3;
    }
  }
  &__question {
    padding: tovw(26) tovw(32);
    border-radius: tovw(26);
    position: relative;
    background-color: #f1daff;
    margin-bottom: tovw(24);
    &::before {
      content: "";
      position: absolute;
      bottom: tovw(5);
      right: tovw(-14);
      width: tovw(26);
      height: tovw(14);
      background: url(/images/arrow-right1.png) no-repeat;
      background-size: 100% 100%;
    }
    h3 {
      font-size: tovw(18);
      color: #743b96;
      text-transform: uppercase;
      font-family: $font-heading;
      font-weight: bold;
    }
    p {
      font-size: tovw(18);
      color: #000000;
      margin-bottom: 0;
    }
  }
  &__answer {
    padding: tovw(26) tovw(32);
    border-radius: tovw(26);
    position: relative;
    background-color: #743b96;
    font-size: tovw(18);
    color: #fff;
    &::before {
      content: "";
      position: absolute;
      bottom: tovw(5);
      left: tovw(-14);
      width: tovw(27);
      height: tovw(17);
      background: url(/images/arrow-left1.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .swiper-slide {
    &:nth-child(2n) {
      .qa {
        &__question {
          background-color: #bff8e3;
          h3 {
            color: #22bf86;
          }
          &::before {
            background: url(/images/arrow-right2.png) no-repeat;
            background-size: 100% 100%;
          }
        }
        &__answer {
          background-color: #22bf86;
          &::before {
            background: url(/images/arrow-left2.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    &:nth-child(3n) {
      .qa {
        &__question {
          background-color: #d5e6ff;
          h3 {
            color: #5849fa;
          }
          &::before {
            background: url(/images/arrow-right3.png) no-repeat;
            background-size: 100% 100%;
          }
        }
        &__answer {
          background-color: #5849fa;
          &::before {
            background: url(/images/arrow-left3.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
.swiper-qa {
  padding: 0 0 tovw(60) 0 !important;
  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-pagination-bullet {
    bottom: tovw(0) !important;
    width: tovw(12) !important;
    height: tovw(12) !important;
    background-color: #07f468;
    opacity: 1 !important;
    &-active {
      width: tovw(22) !important;
      height: tovw(22) !important;
    }
  }
}
.spin {
  &__sub-title {
    margin-top: tovw(20);
    text-align: center;
    position: relative;
    margin-bottom: tovw(25);
    h3 {
      font-size: tovw(20);
      color: #fff;
      font-family: $font-heading;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  &__total-view {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      font-size: tovw(18);
      margin-bottom: 0;
      margin-right: tovw(20);
    }
    .btn {
      min-width: tovw(220) !important;
    }
  }
  &__turns {
    text-align: center;
    // margin-bottom: tovw(18);
    span {
      display: inline-block;
      min-width: tovw(215);
      height: tovw(54);
      line-height: tovw(54);
      text-transform: uppercase;
      font-size: tovw(18);
      font-family: $font-heading;
      font-weight: bold;
      color: #000;
      background-color: #fff;
      text-align: center;
      border: 1px solid #07f468;
      border-radius: tovw(27);
    }
  }
  &__wrap {
    position: relative;
    margin-top: tovw(-100);
    max-width: tovw(494);
  }
  &__rewards {
    position: relative;
    z-index: 2;
    width: tovw(494);
    height: tovw(494);
    background: url(/images/spin.png) no-repeat;
    background-size: 100% 100%;
    margin-top: tovw(50);
  }
  &__item {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: tovw(137);
    height: tovw(247);
    text-align: center;
    padding-top: tovw(35);
    transform-origin: center bottom;
    img {
      height: tovw(91);
    }
  }
  &__ani {
    position: relative;
  }
  &__kim {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: tovw(207);
    height: tovw(207);
    background: url(/images/logo-nssc.png) no-repeat;
    background-size: 100% 100%;
    z-index: 2;
    &::before {
      content: "";
      position: absolute;
      bottom: tovw(-24);
      left: 50%;
      transform: translateX(-50%);
      width: tovw(36);
      height: tovw(28);
      background: url(/images/arrow-up.png) no-repeat;
      background-size: 100% 100%;
      z-index: 2;
    }
  }
  &__actions {
    margin-top: tovw(25);
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      min-width: tovw(215) !important;
      width: tovw(215);
      height: tovw(54) !important;
      line-height: tovw(54) !important;
      margin-bottom: tovw(15);
      border-radius: tovw(27);
      box-shadow: 4.045px 2.939px 10px 0px rgba(0, 0, 0, 0.56) !important;
      font-weight: bold;
      &.mr {
        margin-right: tovw(15);
      }
      &.white {
        background-color: #fff !important;
      }
    }
  }
  &__pedestal {
    position: absolute;
    top: tovw(439);
    left: tovw(-56);
    width: tovw(611);
    height: tovw(390);
    background: url(/images/pedestal.png) no-repeat;
    background-size: 100% 100%;
  }
}
.video-daily {
  position: relative;
  .swiper-next-video-daily,
  .swiper-prev-video-daily {
    width: tovw(21) !important;
    height: tovw(48) !important;
    margin-top: tovw(-54) !important;
  }
  &__item {
    text-align: center;
    .btn--gradient {
      min-width: tovw(177);
      height: tovw(40);
      line-height: tovw(40);
      border-radius: tovw(20);
      font-size: tovw(16);
      width: auto;
    }
    &:hover {
      .btn--gradient {
        background-color: #fff !important;
      }
    }
  }
  &__thumb {
    display: block;
    margin-bottom: tovw(20);
  }
}

.video-note {
  text-align: center;
  margin-bottom: 0;
  margin-top: tovw(10);
  font-size: tovw(18);
  color: #fff;
}
.video-rewards {
  margin-top: tovw(40);
  &__title {
    text-align: center;
    margin-bottom: tovw(15);
    img {
      width: 100%;
    }
  }
  &__list {
    position: relative;
    .swiper-next-video-rewards,
    .swiper-prev-video-rewards {
      width: tovw(21) !important;
      height: tovw(48) !important;
      margin-top: tovw(-54) !important;
    }
    .swiper-next-video-rewards {
      right: tovw(-20) !important;
    }
    .swiper-prev-video-rewards {
      left: tovw(-20) !important;
    }
  }
  &__item {
    position: relative;
    padding: 0 tovw(20);
    > img {
      height: tovw(200);
      margin-bottom: tovw(20);
    }
    .btn {
      min-width: tovw(121);
      height: tovw(40);
      line-height: tovw(40);
      border-radius: tovw(20);
      width: 100%;
      cursor: default;
      font-size: tovw(16);
    }
    &:hover {
      .btn {
        background-color: #fff !important;
      }
    }
  }
  &__received {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: tovw(75);
    height: tovw(75);
  }
}

.modal-info-streamer {
  .info-streamer {
    display: flex;
    flex-wrap: wrap;
    &__img {
      width: 53%;
      height: inherit;
      padding: tovw(3) 0 tovw(3) tovw(3);
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: tovw(8);
        border-bottom-left-radius: tovw(8);
      }
    }
    &__content {
      width: 47%;
      padding: tovw(30) tovw(40);
      text-align: left;
      position: relative;
      h3 {
        font-size: tovw(46);
        font-family: $font-heading;
        margin-bottom: tovw(10);
      }
    }
    &__nickname {
      height: tovw(43) !important;
      line-height: tovw(40) !important;
      span {
        font-family: $roboto;
        color: #fff;
        font-weight: normal;
      }
    }
    &__bir {
      color: #c600ac;
      font-size: tovw(18);
      font-weight: 500;
      text-transform: uppercase;
      font-family: $cruyff;
    }
    &__des {
      font-size: tovw(18);
      color: #010101;
      line-height: tovw(28);
      span {
        color: #c600ac;
        font-weight: 500;
        text-transform: uppercase;
        font-family: $cruyff;
      }
    }
    &__special {
      font-size: tovw(18);
      color: #7100cd;
      text-transform: uppercase;
      line-height: tovw(28);
      font-weight: 500;
      margin-top: tovw(10);
      font-family: $cruyff;
    }
    &__link {
      position: absolute;
      bottom: tovw(-55);
      left: 50%;
      transform: translateX(-50%);
      width: tovw(245);
      height: tovw(115);
      z-index: 2;
      img {
        width: 100%;
      }
    }
  }
}
.modal-video {
  h2 {
    text-align: left;
    padding-left: 0;
    margin-bottom: tovw(20);
    min-height: tovw(55);
    font-family: $cruyff;
    font-weight: bold;
    span {
      text-transform: none;
      font-size: tovw(18);
      color: #7100cd;
      font-family: $roboto;
    }
  }
  &__wrap {
    height: tovw(540);
  }
  &__item {
    width: 100% !important;
    height: 100% !important;
  }
  &__logo {
    position: absolute;
    top: tovw(-97);
    right: tovw(-97);
    width: tovw(302);
    height: tovw(225);
    background: url(/images/logo-nssc2.png) no-repeat;
    background-size: 100% 100%;
  }
}

.match-progress {
  p {
    font-size: tovw(18) !important;
    line-height: 1.2 !important;
  }
  img {
    width: tovw(150);
  }
}

.modal-history {
  h2 {
    margin-bottom: tovw(30);
  }
  .table-history {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    // margin-top: tovw(20);
    th {
      height: tovw(50);
      font-size: tovw(26);
      font-weight: bold;
      text-transform: uppercase;
      color: #7100cd;
      border-bottom: 2px solid #999dbb;
    }
    td {
      color: #7100cd;
      font-size: tovw(20);
      padding: tovw(7) tovw(5);
      height: tovw(55);
      border-bottom: 1px solid #999dbb;
      font-weight: 300;
    }
  }
}

.item-cover {
  &__img {
    height: tovw(236);
  }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  &.swiper-pagination-hidden {
    opacity: 0;
  }
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet {
  border-radius: 50%;
}
