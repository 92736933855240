/// Variables
/// @see https://www.smashingmagazine.com/2016/01/variables-in-css-architecture/

/// Grid
/// Using rem-units to scale for accessibility (based on the user's browser settings, default 1rem equals 16px)
/// @see layout/grid
/// grid class specified below ($breakpoints)

$grid-max-width: 120rem;
$grid-gutter: 1rem;
$grid-columns: 12;
$grid-show-outlines: false; //to show the grid's outlines

/// Breakpoints - used by the grid and media query manager
/// @type Map
/// @prop {size}: [min-width] - breakpoint name and minimum width for media queries
$breakpoints: (
  "xs": 0,
  "sm": 420px,
  "md": 720px,
  "lg": 960px,
  "xl": 1200px,
);

@font-face {
  font-family: "CruyffSansVN";
  src: url("/fonts/CruyffSansVN-Bold.eot");
  src: url("/fonts/CruyffSansVN-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/CruyffSansVN-Bold.woff2") format("woff2"),
    url("/fonts/CruyffSansVN-Bold.woff") format("woff"),
    url("/fonts/CruyffSansVN-Bold.ttf") format("truetype"),
    url("/fonts/CruyffSansVN-Bold.svg#CruyffSansVN-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CruyffSansVN";
  src: url("/fonts/CruyffSansVN-Heavy.eot");
  src: url("/fonts/CruyffSansVN-Heavy.eot?#iefix") format("embedded-opentype"),
    url("/fonts/CruyffSansVN-Heavy.woff2") format("woff2"),
    url("/fonts/CruyffSansVN-Heavy.woff") format("woff"),
    url("/fonts/CruyffSansVN-Heavy.ttf") format("truetype"),
    url("/fonts/CruyffSansVN-Heavy.svg#CruyffSansVN-Heavy") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CruyffSansVN";
  src: url("/fonts/CruyffSansVN_Regular.eot");
  src: url("/fonts/CruyffSansVN_Regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/CruyffSansVN_Regular.woff2") format("woff2"),
    url("/fonts/CruyffSansVN_Regular.woff") format("woff"),
    url("/fonts/CruyffSansVN_Regular.ttf") format("truetype"),
    url("/fonts/CruyffSansVN_Regular.svg#CruyffSansVN_Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CruyffSansVN";
  src: url("/fonts/CruyffSansVN-Light.eot");
  src: url("/fonts/CruyffSansVN-Light.eot?#iefix") format("embedded-opentype"),
    url("/fonts/CruyffSansVN-Light.woff2") format("woff2"),
    url("/fonts/CruyffSansVN-Light.woff") format("woff"),
    url("/fonts/CruyffSansVN-Light.ttf") format("truetype"),
    url("/fonts/CruyffSansVN-Light.svg#CruyffSansVN-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CruyffSansVN";
  src: url("/fonts/CruyffSansVN-Medium.eot");
  src: url("/fonts/CruyffSansVN-Medium.eot?#iefix") format("embedded-opentype"),
    url("/fonts/CruyffSansVN-Medium.woff2") format("woff2"),
    url("/fonts/CruyffSansVN-Medium.woff") format("woff"),
    url("/fonts/CruyffSansVN-Medium.ttf") format("truetype"),
    url("/fonts/CruyffSansVN-Medium.svg#CruyffSansVN-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SVN-Nexa Rush Sans";
  src: url("/fonts/SVN-NexaRushSansBlack.eot");
  src: url("/fonts/SVN-NexaRushSansBlack.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/SVN-NexaRushSansBlack.woff2") format("woff2"),
    url("/fonts/SVN-NexaRushSansBlack.woff") format("woff"),
    url("/fonts/SVN-NexaRushSansBlack.ttf") format("truetype"),
    url("/fonts/SVN-NexaRushSansBlack.svg#SVN-NexaRushSansBlack") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Riffic Free";
  src: url("/fonts/RifficFree-Bold.eot");
  src: url("/fonts/RifficFree-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/RifficFree-Bold.woff2") format("woff2"),
    url("/fonts/RifficFree-Bold.woff") format("woff"),
    url("/fonts/RifficFree-Bold.ttf") format("truetype"),
    url("/fonts/RifficFree-Bold.svg#RifficFree-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/// mq() - media query manager
/// @see vendor/mq
/// @link https://github.com/sass-mq/sass-mq
$mq-breakpoints: $breakpoints;
$mq-base-font-size: 16px;
$mq-show-breakpoints: ();

$windowWidth: 1920;
$windowHeight: 1080;

$cdnUrl: "";

/// Fonts
/// @TODO: choose color variable naming convention
$roboto: "Roboto Condensed", sans-serif;
$riffic: "Riffic Free", sans-serif;
$cruyff: "CruyffSansVN", sans-serif;
$svn: "SVN-Nexa Rush Sans", sans-serif;

$font-size: 1.6rem;
$font-primary: $roboto;
$font-heading: "CruyffSansVN", sans-serif;

$golden: #cdbe91;
$golden-light: #f0e6d2;

/// Colors
$blue: #285366;
$blue-dark: #6396b1;
$blue-light: #6f8eab;
$green: #16efb5;
$orange: #ff7922;
$red: #ff4013;
$pink: #e70b51;
$yellow: #fff000;
$purple: #1c213f;

$white: #fff;
$gray-light: #e5e5e5;
$gray: #7e7e7e;
$gray-dark: #333;
$black: #000;
$smoke: #7f868a;

$color-default: $golden; //default text color

$color-primary: $golden;
$color-primary-light: $golden-light;
$color-primary-dark: $golden;

$color-secondary: $green;
$color-secondary-light: lighten($green, 10%);
$color-secondary-dark: darken($green, 10%);

$color-success: green;
$color-warning: orange;
$color-alert: red;

/// Form
$form-height: 40px;

/// Dimensions - Widths and heights
$height-header: 100px; //example!
$height-footer: 100px; //example!
